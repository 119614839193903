import { useQuery, keepPreviousData } from '@tanstack/vue-query'
import type { LeaseRegisterLeaseSearchScope, ILeaseSimple } from '@register'

export const useApiLeaseDocumentsSearch = (
  companyId: string,
  scope?: LeaseRegisterLeaseSearchScope,
) => {
  const searchTerm = ref('')
  const query = useQuery<ILeaseSimple[]>({
    queryKey: [
      'getMeImportDocuments',
      companyId,
      'search',
      { searchTerm, scope },
    ],
    queryFn: () =>
      fetchAPI<ILeaseSimple[]>(
        `/companies/${companyId}/leaseRegister/leases/search`,
        {
          query: {
            searchTerm: searchTerm.value,
            scope,
          },
        },
      ),
    placeholderData: keepPreviousData,
    gcTime: 0,
    staleTime: 0,
    meta: {
      cache: false,
    },
  })

  return {
    ...query,
    search: useDebounceFn((value: string) => (searchTerm.value = value), 500),
    searchTerm,
  }
}
